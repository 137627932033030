import React, { useState, useEffect, useCallback } from 'react'
import { Toast, ToastBody, ToastHeader } from 'reactstrap'
const typeClasses = {
  success: 'success',
  danger: 'danger',
  warning: 'warning'
}
const EdgeToast = ({ type, title, content, isOpen, toggle }) => {
  const [toastClass, setToastClass] = useState({})

  useEffect(() => {
    setToastClass(typeClasses[type])
  }, [type])

  return (
    <Toast isOpen={isOpen} className={`edge-toast ${toastClass}`}>
      <ToastHeader toggle={toggle} className="justify-content-between">{title}</ToastHeader>
      <ToastBody onClick={toggle}>{content}</ToastBody>
    </Toast>
  )
}
export default EdgeToast
