import React, { useState, useEffect, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Card } from 'reactstrap';
import BaseAPI from '../helpers/base-api';
const baseAPI = new BaseAPI();

const Footer = React.lazy(() => import('../components/Footer'));
const Logout = React.lazy(() => import('../components/Logout'));
const WizardSideBar = React.lazy(() => import('../pages/signup/components/WizardSideBar'));
const MobileWizardProgressBar = React.lazy(() => import('../pages/signup/components/MobileWizardProgressBar'));
const WizardTopBar = React.lazy(() => import('../components/WizardTopBar'));

const emptyLoading = () => <div />;
const loading = () => <div className="text-center" />;
let socket;
const Register = (props) => {
    const dispatch = useDispatch();

    const [children, setChildren] = useState(null);
    const layout = useSelector((state) => state.Layout);
    const user = useSelector((state) => state.Auth);

    const [menuId, setMenuId] = useState('');
    const [menuStatus, setMenuStatus] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        if (document.body) document.body.classList.add('register-wizard');
        setChildren(props.children);
        return () => {
            if (document.body) document.body.classList.remove('authentication-bg');
        };
    }, [dispatch]);

    useEffect(() => {
        setMenuId(layout.wizardStep);
        setMenuStatus(user.user?.setupStatus);
        setUserId(user.user?.id);
    }, [user, layout]);

    const openLeftMenu = () => {
        document.body.classList.add('sidebar-enable');
    };

    return (
        <div className="app">
            <div className="wizard-wrapper">
                <Suspense fallback={emptyLoading()}>
                    <WizardSideBar menuId={menuId} status={menuStatus} type={'setup'} />
                </Suspense>
                <div className="wizard-container">
                    <Suspense fallback={emptyLoading()}>
                        <WizardTopBar {...props} openLeftMenuCallBack={openLeftMenu} hideLogo />
                        <MobileWizardProgressBar menuId={menuId} status={menuStatus} type={'setup'} />
                    </Suspense>
                    <Suspense fallback={loading()}>{children}</Suspense>
                </div>
                <Suspense fallback={loading()}>
                    <Footer {...props} />
                </Suspense>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { user } = state.Auth;
    const layout = state.Layout;
    return { user, layout };
};

export default connect(mapStateToProps)(Register);
