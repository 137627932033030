// @flow
import {
    ISSUE_CARD_LIST,
    ISSUE_CARD_LIST_SUCCESS,
    ISSUE_CARD_LIST_FAILED,
    TRANSACTIONS,
    TRANSACTIONS_SUCCESS,
    TRANSACTIONS_FAILED,
} from './constants';

type FundAction = { type: string, payload: {} | string };

export const getIssueCards = (userId: string): FundAction => ({
    type: ISSUE_CARD_LIST,
    payload: { userId },
});

export const getIssueCardsSuccess = (card: string): FundAction => ({
    type: ISSUE_CARD_LIST_SUCCESS,
    payload: card,
});

export const getIssueCardsFailed = (error: string): FundAction => ({
    type: ISSUE_CARD_LIST_FAILED,
    payload: error,
});

export const getTransactions = (userId: string): FundAction => ({
    type: TRANSACTIONS,
    payload: { userId },
});

export const getTransactionsSuccess = (transactions: string): FundAction => ({
    type: TRANSACTIONS_SUCCESS,
    payload: transactions,
});

export const getTransactionsFailed = (error: string): FundAction => ({
    type: TRANSACTIONS_FAILED,
    payload: error,
});
