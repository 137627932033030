import { create } from 'apisauce'
import { prop } from 'ramda'
import { getUserAccessToken } from '../helpers/authUtils'

class BaseAPI {
  constructor (includeToken) {
    if (includeToken === null || includeToken === undefined) {
      this.includeToken = true
    } else {
      this.includeToken = false
    }
    this.HTTP = create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    })
    this.HTTP.addResponseTransform((response) => {
      const ok = prop('ok', response)
      const data = prop('data', response)
      const problem = prop('problem', response)

      const transformId = (obj) => {
        if (obj && obj._id) {
          obj.id = obj._id
          delete obj._id
        }

        return obj
      }

      if (!ok) {
        let error = { status: 'error' }
        switch (problem) {
          case 'CLIENT_ERROR':
            if (response.status === 401) {
              const isLoggedIn = !!getUserAccessToken()
              localStorage.removeItem('user')
              localStorage.removeItem('tokens')
              if (isLoggedIn) {
                window.location.href = '/account/login'
              } else {
                error = {
                  status: 'error',
                  ...data
                }
                throw error
              }
            } else {
              error = {
                status: 'error',
                ...data
              }
              throw error
            }
            return

          case 'TIMEOUT_ERROR':
            response.status = 408
            error = {
              status: 'error',
              message: 'Network timeout. Please try again.',
              ...data
            }
            break

          case 'CONNECTION_ERROR':
            response.status = 503
            error = {
              status: 'error',
              message: 'Server not available.',
              ...data
            }
            break

          case 'NETWORK_ERROR':
            response.status = 511
            error = {
              status: 'error',
              message: 'Network unavailable.',
              ...data
            }
            break

          case 'CANCEL_ERROR':
            response.status = 500
            error = {
              status: 'error',
              message: 'Request has been cancelled.',
              ...data
            }
            break

          default:
            response.status = 500
            error = {
              status: 'error',
              message: 'System error.',
              ...data
            }
        }
        throw error
      } else {
        if (data.length) {
          response.data = data.map(transformId)
        } else {
          response.data = transformId(data)
        }
      }
    })
  }

  /**
     * Post data to server api
     *
     * @param {String} url Path of URL string example /users/
     * @param {Object} data Data send to server api
     * @param {Object} headers Headers to include in request
     */
  post (url, data = {}, headers = {}) {
    return this.request('post', url, data, null, headers)
  }

  /**
     * Get data from server api
     *
     * @param {String} url Path of URL string example /users/
     * @param {Object} params Parameters append in the request
     * @param {Object} headers Headers to include in request
     */
  get (url, params = {}, headers = {}) {
    return this.request('get', url, null, params, headers)
  }

  /**
     * Delete data on server api
     *
     * @param {String} url Path of URL string example /users/
     * @param {Object} data Data send to server api
     * @param {Object} params Parameters append in the request
     * @param {Object} headers Headers to include in request
     */
  delete (url, params = {}, headers = {}) {
    return this.request('delete', url, {}, params, headers)
  }

  /**
     * Put data to server api
     *
     * @param {String} url Path of URL string example /users/
     * @param {Object} data Data send to server api
     * @param {Object} headers Headers to include in request
     */
  put (url, data = {}, headers = {}) {
    return this.request('put', url, data, null, headers)
  }

  /**
     * Patch data to server api
     *
     * @param {String} url Path of URL string example /users/
     * @param {Object} data Data send to server api
     * @param {Object} headers Headers to include in request
     */
  patch (url, data = {}, headers = {}) {
    return this.request('patch', url, data, null, headers)
  }

  /**
     * General method to make request to server api
     *
     * @param {String} method HTTP method
     * @param {String} url Path of URL string example /users/
     * @param {Object} data Data send to server api
     * @param {Object} params Parameters append in the request
     * @param {Object} headers Headers to include in request
     */
  request (method, url, data, params, headers) {
    const reqHeader = headers != null ? headers : {}
    if (getUserAccessToken()) {
      reqHeader.Authorization = `Bearer ${getUserAccessToken().token}`
      reqHeader.Accept = 'application/json'
    }

    switch (method) {
      case 'get':
        return this.HTTP.get(url, params, {
          headers: reqHeader
        })
      case 'post':
        return this.HTTP.post(url, data, {
          headers: reqHeader
        })
      case 'delete':
        return this.HTTP.delete(url, params, {
          headers: reqHeader
        })
      case 'put':
        return this.HTTP.put(url, data, {
          headers: reqHeader
        })
      case 'patch':
        return this.HTTP.patch(url, data, {
          headers: reqHeader
        })
      default:
        break
    }
  }
}

export default BaseAPI
