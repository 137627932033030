/* LAYOUT */
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT'
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH'
export const CHANGE_LAYOUT_TITLE = 'CHANGE_LAYOUT_TITLE'
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME'
export const CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE'
export const CHANGE_SOCKET_MESSAGE = 'CHANGE_SOCKET_MESSAGE'

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR'
export const SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR'
export const HIDE_RIGHT_SIDEBAR = 'HIDE_RIGHT_SIDEBAR'

export const CHANGE_LAYOUT_WIZARD_STEP = 'CHANGE_LAYOUT_WIZARD_STEP'
export const CHANGE_LAYOUT_BACK_STEP = 'CHANGE_LAYOUT_BACK_STEP'
