import React, { useState, useEffect, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import * as layoutConstants from '../constants/layout';
import { Container, Alert, Button } from 'reactstrap';
import { changeSidebarTheme, changeSidebarType, updateUserData, changeSocketMessage } from '../redux/actions';
import ThemeCustomizer from '../components/ThemeCustomizer';
import { getUserAccessToken } from '../helpers/authUtils';
import EdgeToast from '../components/EdgeToast';
import Navbar from '#components/Navbar.js';
import MinimalTopBar from '#components/MinimalTopBar.jsx';

const WizardTopBar = React.lazy(() => import('../components/WizardTopBar'));
const Footer = React.lazy(() => import('../components/Footer'));
const RightSidebar = React.lazy(() => import('../components/RightSidebar'));

const emptyLoading = () => <div />;
const loading = () => <div className="text-center" />;
let socket;
const Unresolved = (props) => {
    const dispatch = useDispatch();

    const [children, setChildren] = useState(null);

    const layout = useSelector((state) => state.Layout);

    const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
    const user = useSelector((state) => state.Auth);

    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const [timeoutId, setTimeoutId] = useState('');
    const toggleToast = () => setShowToast(!showToast);
    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        const tId = setTimeout(() => {
            setShowToast(false);
        }, 30000);
        setTimeoutId(tId);
    };

    useEffect(() => {
        const token = getUserAccessToken() ? getUserAccessToken().token : '';
        // if (socket && socket.connected) {
        //     socket.disconnect();
        // }
        if (!socket && token) {
            socket = io(ENDPOINT);
            socket.emit('login', { token }, (res) => {
                console.log('data emit(project-message)');
                console.log(res);
            });
            socket.on('message', (message) => {
                showMessageToast('success', 'New Boost', message.text);
            });

            socket.on('plaid.failed-verify', (message) => {
                dispatch(changeSocketMessage('plaid.failed-verify'));
                showMessageToast('danger', message.title, message.text);
            });
        }

        return () => {
            if (socket && socket.connected) {
                // socket.disconnect();
            }
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [ENDPOINT, user]);

    useEffect(() => {
        setChildren(props.children);

    }, [dispatch]);


    return (
        <div className="app">
            <div className="wrapper">
                <div className="content-page-minimal">
                    <div className="content">
                        <Suspense fallback={emptyLoading()}>
                            <MinimalTopBar {...props} />
                        </Suspense>
                        <div className="global-notifications"></div>
                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={emptyLoading()}>
                        <Footer {...props} />
                    </Suspense>
                </div>
                <EdgeToast
                    isOpen={showToast}
                    toggle={toggleToast}
                    type={toastType}
                    title={toastTitle}
                    content={toastContent}
                />
            </div>

            <Suspense fallback={emptyLoading()}>
                <RightSidebar {...props}>
                    <ThemeCustomizer />
                </RightSidebar>
            </Suspense>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { user } = state.Auth;
    return { user };
};

export default connect(mapStateToProps)(Unresolved);
