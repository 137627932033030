import BaseAPI from '../helpers/base-api'
const baseAPI = new BaseAPI()

export const createUser = (data) => baseAPI.post('auth/register', data)

export const login = (data) => baseAPI.post('auth/login', data)

export const forgetPassword = (data) => baseAPI.post('auth/forgot-password', data)

// Stripe API
export const getIssueCards = () => baseAPI.get('fund/issue-cards')

export const createPaymentMethod = (data) => baseAPI.post('fund/payment-methods', data)
export const getPaymentMethods = (data) => baseAPI.get('fund/payment-methods')
export const getPaymentIntents = (data) => baseAPI.get('fund/payment-intents')

export const getTransactions = () => baseAPI.get('stripe/transactions')
