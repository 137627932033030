import React from 'react';
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import { useSelector } from 'react-redux';

const SuspendedUser = () => {
  const userData = useSelector((state) => state.Auth.user);
  return (
    <>
      <section className="section">
        <div className="text-center">
          <h3>Your account is currently in {userData.status} status.</h3>
          <br />
          <p>
            Please contact support at <a href='mailto:launch@edgemarkets.io'>launch@edgemarkets.io</a> to help you with this issue.
          </p>
          <p>You may also start a chat on the bottom left</p>
        </div>
      </section>
    </>
  );
};

export default SuspendedUser;
