import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { changeLayout, changeLayoutWizardStep } from '#redux/actions.js';
import { SETUP_MENU_ITEM_MAP } from '#constants/layout.js';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import UnresolvedLayout from '../layouts/Unresolved.jsx';
import VerticalLayout from '../layouts/VerticalLayout';
import SetupLayout from '../layouts/Setup';
import Suspended from '../pages/my-account/Suspended';

const PrivateRoute = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const getLayout = () => {
        let layout = VerticalLayout;
        if (location.pathname.indexOf('/setup') === 0) {
            dispatch(changeLayout('setup'));
            document.body.classList.remove('authentication-bg');
            if (document.body) document.body.classList.add('register-wizard');
            layout = SetupLayout;
            const setupStep = location.pathname.split('/')[2] || 'deposit-agreements';
            dispatch(changeLayoutWizardStep(SETUP_MENU_ITEM_MAP[setupStep]));
        } else {
            document.body.classList.remove('authentication-bg');
            document.body.classList.remove('register-wizard');
            layout = VerticalLayout;
            dispatch(changeLayout('default-layout'));
        }
        return layout;
    };

    let Layout = getLayout();

    if (!isUserAuthenticated()) {
        window.location.href = '/account/login';
        return null;
    }

    const loggedInUser = getLoggedInUser();

    if (loggedInUser.setupStatus === 'complete') {
        if (loggedInUser.status !== 'active') {
            return (
                <UnresolvedLayout>
                    <Suspended />
                </UnresolvedLayout>
            );
        }

        if (
            loggedInUser.crossRiverCustomer?.ofac === 'Failed' ||
            loggedInUser.crossRiverCustomer?.pepScan === 'Failed'
        ) {
            return (
                <UnresolvedLayout>
                    <div className="mt-5">
                        <div className="text-center">
                            <h3>
                                Thank you for your interest!
                                <br />
                                <br />
                                Unfortunately we are unable to approve your application at this time.
                                <br />
                                <br />
                                You will receive an adverse action letter within 7 days.
                            </h3>
                        </div>
                    </div>
                </UnresolvedLayout>
            );
        }
        return (
            <Layout {...props}>
                <Outlet />
            </Layout>
        );
    } else {
        if (window.location.pathname.indexOf('/setup') === 0) {
            return (
                <Layout {...props}>
                    <Outlet />
                </Layout>
            );
        } else {
            return <Navigate to={`/setup/${loggedInUser.setupStatus}`} />;
        }
    }
};

export default PrivateRoute;
