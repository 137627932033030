// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_LAYOUT_TITLE,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
  CHANGE_LAYOUT_WIZARD_STEP,
  CHANGE_LAYOUT_BACK_STEP,
  CHANGE_SOCKET_MESSAGE,
} from './constants'

export const changeLayout = (layout) => ({
  type: CHANGE_LAYOUT,
  payload: layout
})

export const changeLayoutWidth = (width) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: width
})

export const changeSidebarTheme = (theme) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: theme
})

export const changeSidebarType = (sidebarType) => ({
  type: CHANGE_SIDEBAR_TYPE,
  payload: sidebarType
})

export const toggleRightSidebar = () => ({
  type: TOGGLE_RIGHT_SIDEBAR,
  payload: null
})

export const showRightSidebar = () => ({
  type: SHOW_RIGHT_SIDEBAR,
  payload: null
})

export const hideRightSidebar = () => ({
  type: HIDE_RIGHT_SIDEBAR,
  payload: null
})

export const changeLayoutTitle = (title) => ({
  type: CHANGE_LAYOUT_TITLE,
  payload: {title}
})

export const changeLayoutWizardStep = (wizardStep) => ({
  type: CHANGE_LAYOUT_WIZARD_STEP,
  payload: wizardStep
})

export const changeLayoutBackStep = (backStep) => ({
  type: CHANGE_LAYOUT_BACK_STEP,
  payload: backStep
})

export const changeSocketMessage = (message) => ({
  type: CHANGE_SOCKET_MESSAGE,
  payload: message
})
