import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MetisMenu from 'metismenujs';
import { initMenu, changeActiveMenuFromLocation } from '../redux/actions';

const MenuItemWithChildren = ({
    item,
    linkClassNames,
    subMenuClassNames,
    activatedMenuItemIds,
    menuItemClick,
    subMenuItemClick,
    user,
}) => (
    <li
        className={classNames('side-nav-item', {
            'mm-active': activatedMenuItemIds.indexOf(item.id) >= 0,
            active: activatedMenuItemIds.indexOf(item.id) >= 0,
        })}
    >
        <Link
            to="#"
            onClick={(e) => {
                e.preventDefault();
                menuItemClick(item);
            }}
            className={classNames('has-arrow', 'side-sub-nav-link', linkClassNames)}
            aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}
        >
            {item.icon && <i className={item.icon}></i>}
            {item.badge && (
                <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>
            )}
            <span> {item.name} </span>
        </Link>

        <ul
            className={classNames(subMenuClassNames, 'mm-collapse', {
                'mm-collapsed mm-show': activatedMenuItemIds.indexOf(item.id) >= 0,
            })}
            aria-expanded={activatedMenuItemIds.indexOf(item.id) >= 0}
        >
            {item.children.map((child, i) => (
                <React.Fragment key={i}>
                    {child.children ? (
                        child.excludeState && user?.isBlackListState ? (
                            <></>
                        ) : (
                            <MenuItemWithChildren
                                item={child}
                                user={user}
                                linkClassNames=""
                                activatedMenuItemIds={activatedMenuItemIds}
                                subMenuClassNames="side-nav-third-level"
                            />
                        )
                    ) : child.excludeState && user?.isBlackListState ? (
                        <></>
                    ) : (
                        <MenuItem
                            item={child}
                            className={classNames({ 'mm-active': activatedMenuItemIds.indexOf(child.id) >= 0 })}
                            linkClassName=""
                            isActive={activatedMenuItemIds.indexOf(child.id) >= 0}
                            menuItemClick={subMenuItemClick}
                        />
                    )}
                </React.Fragment>
            ))}
        </ul>
    </li>
);

const MenuItem = ({ item, className, linkClassName, isActive, menuItemClick }) => (
    <li className={classNames('side-nav-item', className)}>
        <MenuItemLink item={item} className={linkClassName} isActive={isActive} menuItemClick={menuItemClick} />
    </li>
);

const MenuItemLink = ({ item, className, isActive, menuItemClick }) => (
    <Link
        to={item.path}
        className={classNames('side-nav-link-ref', 'side-sub-nav-link', className)}
        onClick={(e) => {
            if (isActive) e.preventDefault();
            else menuItemClick(item);
        }}
    >
        {item.icon && <i className={item.icon}></i>}
        {item.badge && <span className={`badge badge-${item.badge.variant} float-right`}>{item.badge.text}</span>}
        <span> {item.name} </span>
    </Link>
);

const AppMenu = ({
    initMenu,
    changeActiveMenuFromLocation,
    menu,
    mode = 'vertical',
    user,
}) => {
    const [activatedMenuItemIds, setActivatedMenuItemIds] = useState([]);
    const [mainMenuId, setMainMenuId] = useState(0);
    const menuRef = useRef(null);
    const openedMenuItems = useRef([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!menu.menuItems) initMenu();
        initMenuFunction();

        // Listen for location changes
        const unlisten = () => {
            if (document.body) {
                document.body.classList.remove('sidebar-enable');
            }

            if (menuRef.current && openedMenuItems.current.length) {
                for (const el of openedMenuItems.current) {
                    menuRef.current.hide(el);
                }
                openedMenuItems.current = [];
            }
            changeActiveMenuFromLocation();
        };

        // Call unlisten function manually on initial render
        unlisten();

        return () => {
            // Remove listener manually
            unlisten();
        };
    }, [menu.menuItems, location.pathname]);

    useEffect(() => {
        if (mode === 'horizontal') {
            const menuInstance = new MetisMenu('#menu-bar').on('shown.metisMenu', (event) => {
                openedMenuItems.current.push(event.detail.shownElement);
                const menuClick = (e) => {
                    if (!event.target.contains(e.target)) {
                        menuInstance.hide(event.detail.shownElement);
                        window.removeEventListener('click', menuClick);
                    }
                };
                window.addEventListener('click', menuClick);
            });
            menuRef.current = menuInstance;
        } else {
            setTimeout(() => {
                setActivatedMenuItemIds(menu.activatedMenuItemIds || []);
            }, 500);
        }
    }, [menu.activatedMenuItemIds]);

    const initMenuFunction = () => {
        if (mode === 'horizontal') {
            const menuInstance = new MetisMenu('#menu-bar').on('shown.metisMenu', (event) => {
                openedMenuItems.current.push(event.detail.shownElement);
                const menuClick = (e) => {
                    if (!event.target.contains(e.target)) {
                        menuInstance.hide(event.detail.shownElement);
                        window.removeEventListener('click', menuClick);
                    }
                };
                window.addEventListener('click', menuClick);
            });
            menuRef.current = menuInstance;
        } else {
            setTimeout(() => {
                setActivatedMenuItemIds(menu.activatedMenuItemIds || []);
            }, 500);
        }
    };

    const mainMenuClick = (item) => {
        setMainMenuId(item.id);
        setActivatedMenuItemIds([item.id]);
    };

    const subMenuItemClick = (item) => {
        setActivatedMenuItemIds([mainMenuId, item.id]);
    };

    const isHorizontal = mode === 'horizontal';
    let activatedKeys = isHorizontal
        ? []
        : menu
            ? menu.activatedMenuItemIds
                ? menu.activatedMenuItemIds
                : []
            : [] || [];

    let menuItems = menu && menu.menuItems ? menu.menuItems : [];
    const defaultDisplayedItems = window.screen.width > 1366 ? 7 : 5;

    if (isHorizontal && menuItems.length > defaultDisplayedItems) {
        const displayedItems = menuItems.slice(0, defaultDisplayedItems);
        const otherItems = {
            id: menuItems.length + 1,
            path: '/',
            name: 'More',
            icon: 'uil-ellipsis-h',
            children: menuItems.slice(7, menuItems.length),
        };
        menuItems = [...displayedItems, otherItems];
    }

    return (
        <React.Fragment>
            <div className={classNames({ 'topbar-nav': isHorizontal })}>
                {menu && menuItems && menuItems.length ? (
                    <ul className="metismenu side-nav" id="menu-bar">
                        {menuItems.map((item, i) => (
                            <React.Fragment key={item.id}>
                                <React.Fragment>
                                    {item.header && !isHorizontal && (
                                        <li className="side-nav-title side-nav-item" key={i + '-el'}>
                                            {item.header}
                                        </li>
                                    )}
                                    {item.children ? (
                                        item.excludeState && user?.isBlackListState ? (
                                            <></>
                                        ) : (
                                            <MenuItemWithChildren
                                                item={item}
                                                user={user}
                                                subMenuClassNames="side-nav-second-level"
                                                activatedMenuItemIds={activatedMenuItemIds}
                                                linkClassNames="side-nav-link"
                                                menuItemClick={mainMenuClick}
                                                subMenuItemClick={subMenuItemClick}
                                            />
                                        )
                                    ) : item.excludeState && user?.isBlackListState ? (
                                        <></>
                                    ) : (
                                        <MenuItem
                                            item={item}
                                            className={classNames({
                                                'mm-active': activatedMenuItemIds.indexOf(item.id) >= 0,
                                            })}
                                            linkClassName="side-nav-link"
                                            menuItemClick={subMenuItemClick}
                                        />
                                    )}
                                </React.Fragment>
                            </React.Fragment>
                        ))}
                    </ul>
                ) : null}
            </div>
        </React.Fragment>
    );
};

AppMenu.propTypes = {
    initMenu: PropTypes.func.isRequired,
    changeActiveMenuFromLocation: PropTypes.func.isRequired,
    menu: PropTypes.object.isRequired,
    mode: PropTypes.string,
    user: PropTypes.object,
};

const mapStateToProps = (state) => ({
    menu: state.AppMenu,
    user: state.Auth.user,
});

export default connect(mapStateToProps, { initMenu, changeActiveMenuFromLocation })(AppMenu);
