import React from 'react';
import {createRoot} from 'react-dom/client' 
import * as Sentry from '@sentry/react';
import App from './App';

// import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

// Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//     release: process.env.REACT_APP_VERSION,
//     // Enable debug mode if the environment is 'local'
//     //debug: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'local',

//     integrations: [
//         new Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration(),
//         Sentry.reactRouterV5BrowserTracingIntegration({ history }),
//     ],

//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/*\.edgeboost\.io/],

//     // Set tracesSampleRate to .3 to capture 30%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.9,

//     // Capture Replay for 25% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 1.0,
//     replaysOnErrorSampleRate: 1.0,
// });

const root = createRoot(document.getElementById('root'))

root.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
