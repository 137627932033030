// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as API from '../api';

import { ISSUE_CARD_LIST, TRANSACTIONS } from './constants';

import { getIssueCardsSuccess, getIssueCardsFailed, getTransactionsSuccess, getTransactionsFailed } from './actions';

function* getIssueCards() {
    try {
        const response = yield call(API.getIssueCards, {});
        if (response.ok) {
            yield put(getIssueCardsSuccess(response.data));
        } else {
            yield put(getIssueCardsFailed());
        }
    } catch (err) {
        yield put(getIssueCardsFailed());
    }
}

function* getTransactions() {
    try {
        const response = yield call(API.getTransactions, {});
        if (response.ok) {
            yield put(getTransactionsSuccess(response.data));
        } else {
            yield put(getTransactionsFailed());
        }
    } catch (err) {
        yield put(getTransactionsFailed());
    }
}

export function* watchGetIssueCards(): any {
    yield takeEvery(ISSUE_CARD_LIST, getIssueCards);
}

export function* watchGetTransactions(): any {
    yield takeEvery(TRANSACTIONS, getTransactions);
}

function* stripeSaga(): any {
    yield all([fork(watchGetIssueCards), fork(watchGetTransactions)]);
}

export default stripeSaga;
