import { Outlet } from "react-router-dom";
import AuthLayout from '../layouts/Auth';

const AuthRoute = () => {
    return (
        <AuthLayout>
            <Outlet />
        </AuthLayout>
    );
};

export default AuthRoute;