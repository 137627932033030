import { Outlet, Navigate } from "react-router-dom";
import AuthLayout from '../layouts/Auth';
import VerticalLayout from '../layouts/VerticalLayout';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';


const PublicRoute = (props) => {
    const loggedInUser = getLoggedInUser();
    if (!isUserAuthenticated()) {
        console.log('hello')
        return (
            <AuthLayout {...props}>
                <Outlet />
            </AuthLayout>
        );
    } else if (loggedInUser && loggedInUser.setupStatus !== "complete") {
        return (
            <Navigate to={`/setup/${loggedInUser.setupStatus}`} />
        )
    } else {
        return (
        <VerticalLayout {...props}>
            <Outlet />
        </VerticalLayout>
    );
    }


}

export default PublicRoute