// @flow
import {
    ISSUE_CARD_LIST,
    ISSUE_CARD_LIST_SUCCESS,
    ISSUE_CARD_LIST_FAILED,
    TRANSACTIONS,
    TRANSACTIONS_SUCCESS,
    TRANSACTIONS_FAILED,
} from './constants';

const INIT_STATE = {
    issueCards: [],
    transactions: [],
    loading: false,
};

type FundAction = { type: string, payload: {} | string };

const Fund = (state: State = INIT_STATE, action: FundAction) => {
    switch (action.type) {
        case ISSUE_CARD_LIST:
            return { ...state, loading: true };
        case ISSUE_CARD_LIST_SUCCESS:
            return { ...state, issueCards: action.payload, loading: false, error: null };
        case ISSUE_CARD_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case TRANSACTIONS:
            return { ...state, loading: true };
        case TRANSACTIONS_SUCCESS:
            return { ...state, transactions: action.payload, loading: false, error: null };
        case TRANSACTIONS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Fund;
