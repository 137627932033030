import { useEffect, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
const Footer = lazy(() => import('../components/Footer'));
const Logout = lazy(() => import('../components/Logout'));

// loading
const loading = () => <div className="text-center"></div>;

const DefaultLayout = ({ children }) => {
    const props = useSelector((state) => state); // assuming you need the entire state, adjust as needed

    useEffect(() => {
        if (window.location.pathname.indexOf('/register') === 0) {
            if (document.body) document.body.classList.add('register-wizard');
        } else {
            if (document.body) document.body.classList.add('authentication-bg');
        }

        return () => {
            if (document.body) {
                document.body.classList.remove('authentication-bg');
                document.body.classList.remove('register-wizard');
            }
        };
    }, []);

    return (
        <>
            <Suspense fallback={loading()}>
                <Logout {...props} />
            </Suspense>
            <Suspense fallback={loading()}>{children}</Suspense>
            <Suspense fallback={loading()}>
                <Footer {...props} />
            </Suspense>
        </>
    );
};

export default DefaultLayout;
