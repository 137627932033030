// @flow
import React, { Component } from 'react';
import AppRoutes from './routes/AppRoutes';
import WebSocketProvider from './websocket/WebSocket';
import * as Sentry from '@sentry/react';

// Themes
import './assets/scss/Saas.scss';

// configure fake backend
// configureFakeBackend();

type AppProps = {};

function FallbackComponent() {
    return <div>An error has occured. Please contact support@edgemarkets.io </div>;
}

/**
 * Main app component
 */
class App extends Component<AppProps> {
    render() {
        return (
            // <Sentry.ErrorBoundary fallback={FallbackComponent}>
            <AppRoutes></AppRoutes>
            // </Sentry.ErrorBoundary>
        );
    }
}

export default App;
